<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Laba Rugi</strong>
        <a-date-picker
          class="ml-4"
          style="width: 150px"
          v-model="tgl1"
          :allowClear="false"
          format="DD-MM-YYYY"
          @change="filterDate"
        />
        <a-button-group class="ml-1">
          <a-tooltip placement="top">
            <template slot="title">
              <span>Refresh Data</span>
            </template>
            <a-button @click="getAllData(true)">
              <a-icon type="sync" :spin="loadingRefresh" class="text-success" />
            </a-button>
          </a-tooltip>
          <a-tooltip placement="top">
            <template slot="title">
              <span>Export Data</span>
            </template>
            <a-button @click="exportExcel">
              <a-icon type="file-excel" class="text-success" />
            </a-button>
          </a-tooltip>
          <a-tooltip placement="top">
            <template slot="title">
              <span>Print Preview</span>
            </template>
            <a-button @click="exportPdf">
              <a-icon type="printer" class="text-success" />
            </a-button>
          </a-tooltip>
          <a-tooltip placement="top">
            <template slot="title">
              <span>Setting Pengesahan</span>
            </template>
            <a-button @click="showModalPengesahan">
              <a-icon type="setting" class="text-secondary" />
            </a-button>
          </a-tooltip>
        </a-button-group>
      </template>
      <ag-grid-vue
        style="height: 68vh"
        :class="
          settings.theme === 'dark'
            ? 'ag-theme-balham-dark mt-2'
            : 'ag-theme-balham mt-2'
        "
        :key="componentKey"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
        :pagination="allowPagination"
        :paginationPageSize="20"
        :cacheQuickFilter="true"
        :quickFilterText="tablequickfilter"
        :accentedSort="false"
        :sortingOrder="['asc', 'desc']"
        :rowData="rowData"
        @grid-ready="onGridReady"
        :singleClickEdit="false"
        @cell-value-changed="onCellValueChanged"
        :undoRedoCellEditing="true"
        :undoRedoCellEditingLimit="5"
        @first-data-rendered="onFirstDataRendered"
        rowSelection="single"
        @selection-changed="onSelectionChanged"
        :rowClassRules="rowClassRules"
      >
      </ag-grid-vue>
    </a-card>
    <modalpengesahan ref="modalPengesahan" />
  </div>
</template>

<script>
import NProgress from 'nprogress'
import moment from 'moment'
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'
import modalpengesahan from '@/views/app/apps/minibank/settings/modalpengesahan.vue'

export default {
  components: {
    modalpengesahan,
  },
  computed: mapState(['settings', 'user']),
  created() {
    this.gridOptions = {}
    this.getAllData()
  },
  data() {
    return {
      loadingRefresh: false,
      tgl1: moment(),
      componentKey: 0,
      // Property ag-grid
      mode: 'netral',
      theme: this.$store.state.settings.theme,
      gridOptions: null,
      selectedData: null,
      gridApi: null,
      backupRowData: [],
      changedData: [],
      rowData: [],
      tablequickfilter: '',
      allowPagination: false,
      columnDefs: [
        {
          headerName: 'Kode',
          field: 'kode',
          editable: false,
          filter: true,
          resizable: true,
          sortable: true,
        },
        {
          headerName: 'Keterangan',
          field: 'keterangan',
          editable: false,
          filter: true,
          resizable: true,
          width: 150,
          sortable: true,
          // valueFormatter: this.changeJenis,
        },
        {
          headerName: 'Saldo',
          field: 'saldo',
          editable: false,
          filter: true,
          resizable: true,
          sortable: true,
          valueFormatter: this.currencyFormatter,
          type: 'rightAligned',
        },
        // {
        //   headerName: 'Keterangan',
        //   field: 'keterangan',
        //   editable: true,
        //   resizable: true,
        //   sortable: true,
        // },
      ],
      rowClassRules: {
        'text-success': (params) => {
          return params.data.newdata !== undefined
        },
        'text-warning': (params) => {
          return params.data.edited !== undefined
        },
        'text-danger': (params) => {
          return params.data.isError !== undefined
        },
        'text-primary': (params) => {
          return (
            params.data.isPrimary !== undefined && params.data.isPrimary === 1
          )
        },
        'font-weight-bold': (params) => {
          // console.log('params.data.jenis', params.data.jenis)
          return params.data.jenis === 'I'
        },
      },
      // End property

      // Modal Property
      formvisible: false,
      carinasabahtitle: 'Cari',
      inputModal: {
        nama: '',
        alamat: '',
        cabang: '',
      },
    }
  },
  methods: {
    moment,
    // Modal func
    async showModalPengesahan() {
      var res = await lou.customUrlGet2('general/master/2/pengesahan?kode=LaporanLabaRugi')
      this.$refs.modalPengesahan.$data.input = res.data
      // console.log('this.$refs.modalPengesahan.$data.input', this.$refs.modalPengesahan.$data.input)
      this.$refs.modalPengesahan.showModal()
    },
    // End of Modal func
    async exportPdf() {
      // this.amodal.selectedIndex = i
      var imbuhan =
        this.tgl1 !== null && this.tgl1 !== ''
          ? '?tgl=' + this.tgl1.format('YYYY-MM-DD')
          : ''
      var res = await lou.ngetDocumentPdf(
        'laporan/akuntansi/labarugi' + imbuhan + '&preview=pdf',
        false,
        false,
      )
      if (res) {
        // console.log("Success", response);
        const blob = new Blob([res], {
          type: 'application/pdf',
        })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.target = '_blank'
        // link.download = filename
        link.click()
        // const objectUrl = URL.createObjectURL(blob)
        // console.log('objectUrl', objectUrl)
        // this.amodal.ArrayBuffer = objectUrl
        URL.revokeObjectURL(blob)
        setTimeout(() => {
          this.componentKey += 1
          // this.amodalvisible = true
        }, 500)
      }
      // console.log('record.url2_invoice', record.url2_invoice)
      // window.open(record.url2_invoice, '_blank').focus()
    },
    filterDate(date, dateString) {
      this.tgl1 = date
      this.getAllData()
    },
    async getAllData(onclick = false) {
      this.loadingRefresh = true
      // console.log('this.allMaster', this.allMaster)
      var imbuhan =
        this.tgl1 !== null && this.tgl1 !== ''
          ? '?tgl=' + this.tgl1.format('YYYY-MM-DD')
          : ''
      var response = await lou.customUrlGet2(
        'laporan/akuntansi/labarugi' + imbuhan,
        false,
        false,
      )
      if (response) {
        this.rowData = response.data
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
        setTimeout(() => {
          this.loadingRefresh = false
        }, 450)
      } else {
        setTimeout(() => {
          this.loadingRefresh = false
        }, 450)
      }
      // console.log('this.data', this.data)
    },
    // ag-grid function
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize()
      this.input.undoInput = undoSize
      this.input.undoInputdisable = undoSize < 1
      var redoSize = params.api.getCurrentRedoSize()
      this.input.redoInput = redoSize
      this.input.redoInputdisable = redoSize < 1
      if (this.mode === 'oncreate') {
        if (params.data.newdata) {
          var target = this.changedData.findIndex(
            (x) => x.id === params.data.id,
          )
          if (target < 0) {
            this.changedData.push(params.data)
          } else {
            this.changedData[target] = params.data
          }
          // console.log('this.changedData', this.changedData)
        } else {
          // eslint-disable-next-line no-redeclare
          var target = this.rowData.findIndex((x) => x.id === params.data.id)
          var backupTarget = this.backupRowData.findIndex(
            (x) => x.id === params.data.id,
          )
          // console.log('this.rowData[target] before:', this.rowData[target])
          this.rowData[target] = this.$g.clone(
            this.backupRowData[backupTarget],
          )
          // console.log('this.rowData[target] after:', this.rowData[target])
          // console.log('this.backupRowData[backupTarget]', this.backupRowData[backupTarget])
          // this.componentKey += 1
          this.changedData = []
          this.input.undoInput = 0
          this.input.undoInputdisable = true

          this.input.redoInput = 0
          this.input.redoInputdisable = true
          lou.shownotif('Anda sedang dalam mode create')
        }
      } else {
        this.mode = 'onupdate'
        // eslint-disable-next-line no-redeclare
        var targetChanged = this.changedData.findIndex(
          (x) => x.id === params.data.id,
        )
        if (targetChanged < 0) {
          // var cd = {}
          // cd.id = params.data.id
          // cd[params.column.colId] = value.value
          // console.log('cd[params.column.colId]', cd)
          // console.log('value.value', value)
          this.changedData.push(params.data)
        } else {
          this.changedData[targetChanged][params.column.colId] =
            params.newValue
        }
        // console.log('this.changedData', this.changedData)
        // this.componentKey += 1
      }
      // console.log('this.mode', this.mode)
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      this.selectedData = selectedRows
      console.log('selectedRows', selectedRows)
    },
    onFirstDataRendered() {
      this.autoSizeAll(false, { autosizecolumn: true })
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },
    async exportExcel() {
      NProgress.start()
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LB-BPRS Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var alphabeth = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ]
      const worksheet = workbook.addWorksheet(moment().format('DD-MM-YYYY'))

      // Table Data
      var column = this.$g.clone(this.columnDefs)
      var tdata = this.$g.clone(this.rowData)
      var data = []
      tdata.forEach((element) => {
        var retv = {
          ...element,
          tgl:
            element.tgl === null
              ? ''
              : moment(element.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        }
        data.push(retv)
      })
      var cfirst = 0
      var cloop = 0
      var dincrement = 2
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        var hname = element.headerName.toString()
        if (hname !== 'Action') {
          if (cloop === 0) {
            columnLocation.push({
              location: alphabeth[cfirst],
              field: element.field,
            })
            worksheet.getCell(alphabeth[cfirst] + '1').value = hname.replaceAll(
              ' ',
              '',
            )
          } else {
            columnLocation.push({
              location: alphabeth[cloopni] + alphabeth[cfirst],
              field: element.field,
            })
            worksheet.getCell(
              alphabeth[cloopni] + alphabeth[cfirst] + '1',
            ).value = hname.replaceAll(' ', '')
          }
          for (let u = 0; u < data.length; u++) {
            const el = data[u]
            if (cloop === 0) {
              worksheet.getCell(alphabeth[cfirst] + dincrement).value =
                el[element.field]
            } else {
              worksheet.getCell(
                alphabeth[cloopni] + alphabeth[cfirst] + dincrement,
              ).value = el[element.field]
            }
            var checklast = u + 1
            if (checklast === data.length) {
              dincrement = 2
            } else {
              dincrement += 1
            }
          }
          if (cfirst === 25) {
            cfirst = 0
            cloop += 1
          } else {
            cfirst += 1
          }
        }
      }

      /* For sum data */
      // var last = data.length + 3
      // worksheet.mergeCells('A' + last + ':B' + last)
      // worksheet.getCell('A' + last).value = 'Total'
      // for (let ti = 0; ti < this.columnsTotal.length; ti++) {
      //   const element = this.columnsTotal[ti]
      //   var targetLoc = columnLocation.findIndex(x => x.field === element.field)
      //   if (targetLoc >= 0) {
      //     worksheet.getCell(columnLocation[targetLoc].location + last).value = this.rowDataTotal[0][element.field]
      //   }
      //   // console.log(columnLocation[targetLoc].location + last, this.rowDataTotal[0][element.field])
      // }

      // write to a new buffer
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Data Laporan Laba Rugi.xlsx'
      NProgress.done()
      link.click()
    },
    formatTanggal(v) {
      // console.log('v', v)
      // console.log('ntgl', ntgl)
      return moment(v, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    changeJenis(v) {
      console.log('v', v)
      var ret = v.value === 'I' ? 'Induk' : 'Detail'
      return ret
    },
    currencyFormatter(params) {
      // console.log('params', params)
      var ret = params.value === null ? 'NaN' : lou.curency(params.value)
      return ret === 'NaN' ? '' : ret
    },
  },
}
</script>

<style lang="scss" scoped></style>
